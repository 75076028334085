import React, { useState, useEffect, useRef } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"
// Component
import { HandWaving } from "phosphor-react"
import IconsPlayCircular from "../assets/icons/play-circular"
import LayoutWrapper from "../layouts/wrapper"
import Metadata from "../layouts/metadata"
import Header from "../layouts/header"
import { MainContainer } from "../layouts/container"
import Footer from "../layouts/footer"
import Card from "../components/playground/card"
import ImageModal from "../components/playground/image-modal"
// Data
import useProjectData from "../hook/projectpage-data"

// Style
import cardStyle from "../style/components/playground/card.module.scss"
import heroStyle from "../style/section/projectpage/hero.module.scss"

const Spinner = React.forwardRef((props, ref) => {
  return pug`
    div.justify-center.items-center(className=cardStyle.title_circular ref=ref)
      div.flex.justify-center.items-center(className=cardStyle.title_circular_center)
        div(className=cardStyle.title_circular_center_icon)
          HandWaving(size=48)
      div(className=cardStyle.title_circular_spinner)
        IconsPlayCircular
      div(className=cardStyle.title_circular_background)
  `
})

export default function Aboutpage() {
  const fakeData = {
    title: "Cake tiramisu cake apple",
    content: `Chocolate bar cheesecake toffee tiramisu halvah cake. Soufflé tiramisu soufflé sweet roll toffee sugar plum jelly beans.`,
    type: "branding, website design",
  }

  // - Date
  const { edges } = useProjectData()
  if (!edges) return null

  // - Modal open state
  const [isOpen, setIsOpen] = useState(false)
  function toggleModal() {
    setIsOpen(!isOpen)
  }

  // - Header change color
  const bgRef = useRef(null)
  const [isDark, setIsDark] = useState(true)
  function toggleDarkMode(isActive) {
    setIsDark(isActive)
  }

  // fadeInRef Array
  const circularRef = useRef(null)
  const fadeInRef = useRef([])
  fadeInRef.current = []

  // Add children to Ref Array
  const addToRefs = el => {
    if (el && !fadeInRef.current.includes(el)) {
      fadeInRef.current.push(el)
    }
  }

  // -DisableScroll
  useEffect(() => {
    if (typeof window === "undefined" || !window.document) {
      console.log(`windows error`)
      return
    }
    const targetElement = document.querySelector("#layoutWrapper")
    if (isOpen) {
      // disable when open
      disableBodyScroll(targetElement)
    } else {
      // enable when closed
      enableBodyScroll(targetElement)
    }

    // FadeIn Animate
    gsap
      .timeline()
      .set(fadeInRef.current, {
        opacity: 0,
        y: 80,
      })
      .set(circularRef.current, {
        opacity: 0,
        scale: 0,
      })
      .add("LabelA")
      .to(
        fadeInRef.current,
        {
          opacity: 1,
          y: 0,
          stagger: {
            // wrap advanced options in an object
            amount: 0.5,
            ease: "power2.out",
          },
          delay: 1.5,
        },
        "LabelA"
      )
      .to(
        circularRef.current,
        {
          opacity: 1,
          scale: 1,
          delay: 1.8,
          duration: 0.2,
        },
        "LabelA"
      )

    // - Header change color
    gsap.timeline({
      scrollTrigger: {
        id: "headerDarkMode",
        trigger: bgRef.current,
        start: "top top+=50px",
        end: "bottom top+=50px",
        markers: false,
        onToggle: ({ isActive }) => toggleDarkMode(isActive),
      },
    })
    //* 需要重新refresh，不然受到pageTransition的影響，scrollTrigger star end的位置會跑掉
    ScrollTrigger.refresh()
    return () => {
      ScrollTrigger.getById("headerDarkMode").kill()
    }
    // listen for modalShown state
  }, [isOpen, isDark])

  // - get Card index from Card component
  const [isCardIndex, setCardIndex] = useState(0)
  const getCardIndex = e => {
    const indexNum = e.currentTarget.getAttribute("id")
    setCardIndex(indexNum)
  }

  return pug`
    Metadata(title="Play")
    LayoutWrapper.bg-white
      Header(darkmode=isDark)
      div(className=cardStyle.background ref=bgRef)
      MainContainer
        section(className=cardStyle.wrapper)
          div.mb-20
            h1.txt-display1.txt-hasShadow-white-white.mb-4.pl-2(ref=addToRefs) Playground.
            p.text-base.text-theme-200.pl-2(ref=addToRefs) Chocolate bar cheesecake toffee tiramisu halvah cake.
            Spinner(ref=circularRef)
          div(className=cardStyle.shots_wrapper)
            each items,index in edges
              Card(index=index title=fakeData.title content=fakeData.content type=fakeData.type image_url=items.node.data.hero_image.fluid image_alt=items.node.data.hero_image.alt ref=addToRefs onClick=(e)=>{toggleModal();getCardIndex(e)})
      Footer
    // Modal
    ImageModal(isOpen=isOpen onClick=toggleModal onRequestClose=toggleModal closeTimeoutMS=300 title=fakeData.title content=fakeData.content image_url=edges[isCardIndex].node.data.hero_image.fluid)
  `
}
