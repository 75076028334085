import React, { useState, useRef, useEffect } from "react"
import Img from "gatsby-image"
import classnames from "classnames/bind"
// Component
import { ArrowRight } from "phosphor-react"
import ImageModal from "./image-modal"
import ViewMore from "../view-more"
// Style
import cardStyle from "../../style/components/playground/card.module.scss"
import viewStyle from "../../style/components/view-more.module.scss"

const Card = React.forwardRef(
  ({ index, onClick, image_url, image_alt, title, type }, ref) => {
    const cardRef = useRef(null)

    // ViewMore Hover
    const [isHover, setIsHover] = useState(false)

    useEffect(() => {
      const viewMore = cardRef.current.querySelector("#viewMore")
      isHover == true
        ? viewMore.classList.add(viewStyle.hover)
        : viewMore.classList.remove(viewStyle.hover)
    }, [isHover])

    return pug`
    div.col-12.col-md-6.col-lg-4.mb-12.relative(id=index className=cardStyle.card_wrapper onClick=onClick ref=ref onMouseEnter=() => setIsHover(true) onMouseLeave=() => setIsHover(false))
      div(ref=cardRef)
        ViewMore.text-sm.tracking-widest(className=viewStyle.small)
        figure(className=cardStyle.thumbnail_wrapper)
          div(className=cardStyle.thumbnail_container)
            Img(fluid=image_url alt=image_alt style={ height:'100%'})
      div.flex.justify-center.items-start.flex-col(className=cardStyle.description_wrapper)
        h5.font-semibold.uppercase.font-normal.text-theme-100(className=cardStyle.description_title)=title
        // div.flex.justify-center.items-center(className=cardStyle.description_icon)
        //   ArrowRight(size=22 weight="bold")
        p.text-sm.text-theme-200.uppercase(className=cardStyle.description_content)=type
  `
  }
)

export default Card
