import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import classnames from "classnames/bind"
// Component
import { X } from "phosphor-react"
import Modal from "react-modal"
// Style
import modalStyle from "../../style/components/playground/image-modal.module.scss"

const ImageModal = ({
  onClick,
  isOpen,
  image_url,
  image_alt,
  title,
  content,
}) => {
  useEffect(() => {
    // bind modal to appElement
    Modal.setAppElement(document.getElementById("#layoutWrapper"))
  })

  return pug`
    Modal(isOpen=isOpen onRequestClose=onClick contentLabel="Image" className=modalStyle.wrapper overlayClassName=modalStyle.overlay)
      div
        figure(className=modalStyle.image_wrapper)
          Img(fluid=image_url alt=image_alt style={ maxHeight:'100%', maxWidth:'100%', display:'block'})
        div(className=modalStyle.description_wrapper)
          h4.text-gray-100.mb-2(className=modalStyle.description_title)=title
          p.text-gray-200(className=modalStyle.description_content)=content
        X(className=modalStyle.close size=24 onClick=onClick)
  `
}

export default ImageModal
